/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import ogImage from '../data/images/seo/maismilitar-logo.png';

function SEO({ location, description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            contact {
              email
              phone
            }
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaImage = image || ogImage;
  const urlLocation = location ? location.href : site.siteMetadata.siteUrl;

  const regex = /^http|\/\//;
  const urlMetaImage = regex.test(metaImage) ? metaImage : `${site.siteMetadata.siteUrl}${metaImage}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[{ rel: `canonical`, href: urlLocation }]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: urlLocation,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: urlMetaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `business:contact_data:country_name`,
          content: `Brasil`,
        },
        {
          property: `business:contact_data:website`,
          content: site.siteMetadata.siteUrl,
        },
        {
          property: `business:contact_data:region`,
          content: `PR`,
        },
        {
          property: `business:contact_data:email`,
          content: site.siteMetadata.contact.email,
        },
        {
          property: `business:contact_data:phone_number`,
          content: site.siteMetadata.contact.phone,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:description`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: urlMetaImage,
        },
        {
          name: `geo.placename`,
          content: `Curitiba`,
        },
        {
          name: `geo.region`,
          content: `BR`,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  location: null,
  lang: `pt-Br`,
  meta: [],
  description: ``,
  image: null,
};

SEO.propTypes = {
  location: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
};

export default SEO;
