import React from 'react';
import PropTypes from 'prop-types';
import { HeadingWrapper } from './styles';
import themeConstants from '../../../theme/themeConstants';

const { PRIMARY, SECONDARY, DARK, WHITE } = themeConstants.colors;

const Heading = ({ children, noBind, ...props }) => {
  if (noBind) return <HeadingWrapper {...props}>{children}</HeadingWrapper>;

  return <HeadingWrapper dangerouslySetInnerHTML={{ __html: children }} {...props} />;
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  noBind: PropTypes.bool,
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  fontSize: PropTypes.string,
  color: PropTypes.oneOf([PRIMARY, SECONDARY, DARK, WHITE]),
  responsive: PropTypes.object,
};

Heading.defaultProps = {
  as: 'h2',
  color: DARK,
  noBind: false,
};

export default Heading;
