import styled, { css } from 'styled-components';

export const SocialLinkWrap = styled.a`
    color: ${props => props.theme.colors.white};
    &:hover {
          color: ${props => props.theme.colors.primary};
        }
    position: relative;
    display: inline-block;
    &:not(:last-child){
        margin-right: ${props => props.space};
    }
    ${props =>
      props.size === 'small' &&
      css`
        svg {
          height: 16px;
          width: 16px;
        }
      `}
    ${props =>
      props.size === 'medium' &&
      css`
        svg {
          width: 20px;
          height: 20px;
        }
      `}
    ${props =>
      props.size === 'large' &&
      css`
        svg {
          width: 25px;
          height: 25px;
        }
      `}
    ${props =>
      props.varient === 'outlined' &&
      css`
        height: 48px;
        width: 48px;
        text-align: center;
        line-height: 46px;
        background: transparent;
        color: ${props => props.theme.colors.primaryLight};
        border: 1px solid ${props => props.theme.colors.primaryLight};
        svg {
          width: 18px;
          height: 18px;
        }
        &:hover {
          background: ${props => props.theme.colors.secondary};
          color: ${props => props.theme.colors.white};
          border: 1px solid ${props => props.theme.colors.secondary};
        }
        ${props =>
          props.skin === 'light' &&
          css`
            border-color: #fff;
            &:hover {
              color: ${props => props.theme.colors.themeColor};
              background: #fff;
            }
          `}
      `}
    ${props =>
      props.shape === 'rounded' &&
      css`
        border-radius: 50%;
      `} 
    ${props =>
      props.tooltip &&
      css`
        .title {
          position: absolute;
          z-index: 1000000;
          pointer-events: none;
          text-transform: capitalize;
          bottom: 100%;
          visibility: hidden;
          opacity: 0;
          background: ${({ theme, tooltip_bg }) => theme.colors[tooltip_bg === 'dark' ? 'primary' : 'secondary']};
          color: white;
          min-height: 30px;
          padding: 0 10px;
          line-height: 30px;
          font-size: 14px;
          white-space: nowrap;
          border-radius: 5px;
          left: 50%;
          transform: translateX(-50%);
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
          transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24),
            -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
          &:after {
            position: absolute;
            content: '';
            z-index: 1000000;
            top: 100%;
            background: transparent;
            border: 7px solid transparent;
            z-index: 1000001;
            border-top-color: ${props => props.theme.colors.secondary};
            left: calc(50% - 7px);
          }
        }
        &:hover {
          .title {
            visibility: visible;
            opacity: 1;
            transform: translateX(-50%) translateY(-8px);
          }
        }

        ${props =>
          props.tooltip_bg === 'dark' &&
          css`
            .title {
              background: ${props => props.theme.colors.primary};
              &:after {
                border-top-color: ${props => props.theme.colors.primary};
              }
            }
          `}
        ${props =>
          props.tooltip_position === 'bottom-left' &&
          css`
            .title {
              bottom: auto;
              left: 50%;
              transform: translateX(-100%);
              top: 100%;
              left: 100%;
              &:after {
                border-bottom-color: ${props => props.theme.colors.themeColor};
                border-top-color: transparent;
                top: auto;
                bottom: 100%;
                left: calc(100% - 20px);
              }
              ${props =>
                props.tooltip_bg === 'dark' &&
                css`
                  &:after {
                    border-bottom-color: ${props => props.theme.colors.primary};
                    border-top-color: transparent;
                  }
                `}
            }
            &:hover {
              .title {
                transform: translateX(-100%) translateY(8px);
              }
            }
          `}
      `}
    ${props =>
      props.hover &&
      css`
        &:hover {
          color: ${props => props.hover.color};
        }
      `}
`;
