import styled, { css } from 'styled-components';
import { device } from '../../../theme';

export const TextWrapper = styled.p`
  ${({ theme, color, fontSize, align, lineHeight, maxWidth, fontWeight, pt, pb, m, mt, mb, ml, mr, responsive }) => css`
    color: ${theme.colors[color]};
    font-size: ${theme.fontSizes.typography[fontSize]};
    text-align: ${align};
    line-height: ${lineHeight};
    max-width: ${maxWidth};
    font-weight: ${theme.fontWeight[fontWeight]};
    padding-top: ${pt};
    padding-bottom: ${pb};
    margin: ${m};
    margin-top: ${mt};
    margin-bottom: ${mb};
    margin-left: ${ml};
    margin-right: ${mr};
    ${responsive &&
    css`
        ${
          responsive.xlarge &&
          css`
            @media ${device.xlarge} {
              font-size: ${responsive.xlarge.fontSize};
              text-align: ${responsive.xlarge.align};
              line-height: ${responsive.xlarge.lineHeight};
              max-width: ${responsive.xlarge.maxwidth};
              font-weight: ${responsive.xlarge.font2eight};
              padding-top: ${responsive.xlarge.pt};
              padding-bottom: ${responsive.xlarge.pb};
              margin: ${responsive.xlarge.m};
              margin-top: ${responsive.xlarge.mt};
              margin-bottom: ${responsive.xlarge.mb};
              margin-left: ${responsive.xlarge.ml};
              margin-right: ${responsive.xlarge.mr};
            }
          `
        }
        ${
          responsive.large &&
          css`
            @media ${device.large} {
              font-size: ${responsive.large.fontSize};
              text-align: ${responsive.large.align};
              line-height: ${responsive.large.lineHeight};
              max-width: ${responsive.large.maxwidth};
              font-weight: ${responsive.large.font2eight};
              padding-top: ${responsive.large.pt};
              padding-bottom: ${responsive.large.pb};
              margin: ${responsive.large.m};
              margin-top: ${responsive.large.mt};
              margin-bottom: ${responsive.large.mb};
              margin-left: ${responsive.large.ml};
              margin-right: ${responsive.large.mr};
            }
          `
        }
        ${
          responsive.medium &&
          css`
            @media ${device.medium} {
              font-size: ${responsive.medium.fontSize};
              text-align: ${responsive.medium.align};
              line-height: ${responsive.medium.lineHeight};
              max-width: ${responsive.medium.maxwidth};
              font-weight: ${responsive.medium.font2eight};
              padding-top: ${responsive.medium.pt};
              padding-bottom: ${responsive.medium.pb};
              margin: ${responsive.medium.m};
              margin-top: ${responsive.medium.mt};
              margin-bottom: ${responsive.medium.mb};
              margin-left: ${responsive.medium.ml};
              margin-right: ${responsive.medium.mr};
            }
          `
        }
        ${
          responsive.small &&
          css`
            @media ${device.small} {
              font-size: ${responsive.small.fontSize};
              text-align: ${responsive.small.align};
              line-height: ${responsive.small.lineHeight};
              max-width: ${responsive.small.maxwidth};
              font-weight: ${responsive.small.font2eight};
              padding-top: ${responsive.small.pt};
              padding-bottom: ${responsive.small.pb};
              margin: ${responsive.small.m};
              margin-top: ${responsive.small.mt};
              margin-bottom: ${responsive.small.mb};
              margin-left: ${responsive.small.ml};
              margin-right: ${responsive.small.mr};
            }
          `
        }
        ${
          responsive.xsmall &&
          css`
            @media ${device.xsmall} {
              font-size: ${responsive.xsmall.fontSize};
              text-align: ${responsive.xsmall.align};
              line-height: ${responsive.xsmall.lineHeight};
              max-width: ${responsive.xsmall.maxwidth};
              font-weight: ${responsive.xsmall.font2eight};
              padding-top: ${responsive.xsmall.pt};
              padding-bottom: ${responsive.xsmall.pb};
              margin: ${responsive.xsmall.m};
              margin-top: ${responsive.xsmall.mt};
              margin-bottom: ${responsive.xsmall.mb};
              margin-left: ${responsive.xsmall.ml};
              margin-right: ${responsive.xsmall.mr};
            }
          `
        }`}
  `}
`;
